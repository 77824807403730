<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Rotations </h1>
                </div>
               <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2"/>
               </div>
              </div>
             <v-data-table loading-text="Loading Rotations... Please wait"
                      :loading="!rotations.length"
              :headers= 'headers'
              :items="rotations"
             >
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                      <Button :btnType="'Submit'" :label="'Edit'" @onClick="edit(item)" class="mr-2"/>
                    <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" />
                    </div>
              </template>

             </v-data-table>

              <!-- Dialog for edit  -->
              <Dialog ref="editModal" :title="'Edit'" :width="600">
                <v-row>
                  <v-col>
                    <TextField v-model="formData.name"  :validate="false" :label="'Max work hrs, days/month'"/>
                  </v-col>
                  <v-col>
                    <TextField :type="'number'"  v-model="formData.maximumWorkHour" :validate="false" :label="'Maximum Work Hours'"/>
                  </v-col>
                  <v-col>
                    <TextField :type="'number'"  v-model="formData.daysPerMonth" :validate="false" :label="'Days per Month'"/>
                  </v-col>
                  <v-col>
                    <TextField :type="'number'" :validate="false" v-model="formData.dailyRate" :label="'Daily Rate'"/>
                  </v-col>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Update'" @onClick="dialog = true" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel('editModal')" />
                </template>
              </Dialog>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
              <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="update" :isLoading="isLoading"/>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { UPDATE_ROTATION, FETCH_ROTATION, DELETE_ROTATION } from '@/store/action-type'
export default {
  components: {
    TextField,
    Button,
    Dialog,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      dialog: false,
      isLoading: false,
      formData: {
        name: '',
        maximumWorkHour: 0,
        daysPerMonth: 0,
        dailyRate: 0,
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Maximum Work Hour',
          value: 'maximumWorkHour'
        },
        {
          text: 'Days per Month',
          value: 'daysPerMonth'
        },
        {
          text: 'Daily Rate',
          value: 'dailyRate'
        },
        {
          text: 'Added By',
          value: 'addedBy'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy'
        },
        {
          text: 'Modified Date',
          value: 'modifiedDate'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    rotations () {
      return this.$store.getters.rotations
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    edit (item) {
      this.$refs.editModal.toggleModal()
      this.formData.name = item.name
      this.formData.maximumWorkHour = item.maximumWorkHour
      this.formData.daysPerMonth = parseInt(item.daysPerMonth)
      this.formData.dailyRate = parseFloat(item.dailyRate)
      this.formData.id = item.id
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    deleteJobtitle () {
      this.$store.dispatch(DELETE_ROTATION, this.formData.id).then((result) => {
        console.log(result)
        this.showAlertMessage('Rotation deleted successfully', 'success')
        // TODO remove this console.log
      }).catch(error => {
        console.log(error.message)
        this.showAlertMessage('Rotation delete failed', 'success')
      }).finally(() => {
        this.$refs.deleteModal.closeModal()
      })
    },
    Add () {
      this.$router.push('/rotation/add')
    },
    update () {
      this.isLoading = true
      this.cancel('editModal')
      this.$store.dispatch(UPDATE_ROTATION, this.formData).then((result) => {
        this.showAlertMessage('Rotation Updated successfully await approval', 'success')
        this.resetForm()
      }).catch(error => {
        if (error.message === 'PendingRequestExists') { this.showAlertMessage('Rotation Pending Approval', 'error') } else {
          this.showAlertMessage('Rotation Update Failed', 'error')
        }
      }).finally(() => {
        this.dialog = false
        this.isLoading = false
      })
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    }

  },
  mounted () {
    this.$store
      .dispatch(FETCH_ROTATION)
      .then(() => {})
      .catch((error) => {
        console.log(error)
        // if (error) {
        //   this.showAlertMessage('Unable to display departments', 'error')
        // }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
